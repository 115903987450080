// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-press-tsx": () => import("./../../../src/pages/press.tsx" /* webpackChunkName: "component---src-pages-press-tsx" */),
  "component---src-pages-preview-tsx": () => import("./../../../src/pages/preview.tsx" /* webpackChunkName: "component---src-pages-preview-tsx" */),
  "component---src-templates-about-tsx": () => import("./../../../src/templates/about.tsx" /* webpackChunkName: "component---src-templates-about-tsx" */),
  "component---src-templates-careers-tsx": () => import("./../../../src/templates/careers.tsx" /* webpackChunkName: "component---src-templates-careers-tsx" */),
  "component---src-templates-category-tsx": () => import("./../../../src/templates/category.tsx" /* webpackChunkName: "component---src-templates-category-tsx" */),
  "component---src-templates-charities-tsx": () => import("./../../../src/templates/charities.tsx" /* webpackChunkName: "component---src-templates-charities-tsx" */),
  "component---src-templates-contact-tsx": () => import("./../../../src/templates/contact.tsx" /* webpackChunkName: "component---src-templates-contact-tsx" */),
  "component---src-templates-disbursements-tsx": () => import("./../../../src/templates/disbursements.tsx" /* webpackChunkName: "component---src-templates-disbursements-tsx" */),
  "component---src-templates-donations-tsx": () => import("./../../../src/templates/donations.tsx" /* webpackChunkName: "component---src-templates-donations-tsx" */),
  "component---src-templates-flex-template-tsx": () => import("./../../../src/templates/FlexTemplate.tsx" /* webpackChunkName: "component---src-templates-flex-template-tsx" */),
  "component---src-templates-get-a-demo-tsx": () => import("./../../../src/templates/get-a-demo.tsx" /* webpackChunkName: "component---src-templates-get-a-demo-tsx" */),
  "component---src-templates-legal-tsx": () => import("./../../../src/templates/legal.tsx" /* webpackChunkName: "component---src-templates-legal-tsx" */),
  "component---src-templates-legalv-2-tsx": () => import("./../../../src/templates/legalv2.tsx" /* webpackChunkName: "component---src-templates-legalv-2-tsx" */),
  "component---src-templates-post-tsx": () => import("./../../../src/templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */)
}

